import React from "react";
import howtoearn01 from "assets/img/howToEarn/howtoearn01.png";
import howtoearn02 from "assets/img/howToEarn/howtoearn02.png";
import howtoearn03 from "assets/img/howToEarn/howtoearn03.png";
import howtoearn04 from "assets/img/howToEarn/howtoearn04.png";
import "./index.scss";

const data = [
  {
    img: howtoearn01,
    des: "Set up your own fugu cave with an explosive content theme.",
  },
  {
    img: howtoearn02,
    des: "Anonymously to @ KOLs on X through the Fugu platform and amplify your influence",
  },
  {
    img: howtoearn03,
    des: "Distribute Fugu codes on various social media platforms or communities, and both you and the invitee will receive points. Points serve as vouchers for token airdrop",
  },
  {
    img: howtoearn04,
    des: "drive the price of Fugu tokens to the moon",
  },
];
const HowEarn = () => {
  return (
    <div className="how_earn_box">
      <div className="how_earn_title">How to earn</div>
      <div className="answer_box">
        {data.map((item, index) => (
          <div
            key={index}
            className={index === 0 || index === 2 ? "margin-right" : ""}
          >
            <img src={item.img} alt=""></img>
            <p>{item.des}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default HowEarn;
