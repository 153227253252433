import React from "react";
import Vectorzise from "assets/img/bg/Vectorzise.png";
import leftFish from "assets/img/bg/leftFish.gif";
import "./index.scss";

const data = [
  {
    title: "On March 20th",
    color: "#ff4000",
    bgColor: "#ffe501",
    des: "the first version officially goes live.",
  },
  {
    title: "In April",
    color: "#14f195",
    bgColor: "#eb54bc",
    des: "the private placement for Fugu token begins.",
  },
  {
    title: "In July-August",
    color: "#f114db",
    bgColor: "#54d0eb",
    des: "the AI text-to-video generation version goes online, enabling any text content to be turned into videos.",
  },
  {
    title: "In March",
    color: "#5404fd",
    bgColor: "#fa4338",
    des: "more than 50 Twitter KOLs join.",
  },
  {
    title: "In May",
    color: "#ffe501",
    bgColor: "#ff754a",
    des: "the token goes live on major exchanges.",
  },
  {
    title: "Integration with APIs",
    color: "#19ada4",
    bgColor: "#84ff4a",
    des: "Integration with APIs of dozens of social media platforms, leading to a constant stream of popular content.",
  },
];
const RoadMap = () => {
  const firstGroup = data.slice(0, 3);
  const secondGroup = data.slice(3, 6);

  const renderMapGroup = (group, isFirstGroup = false) => (
    <div className={isFirstGroup ? "first_group" : ""}>
      {group.map((item, index) => (
        <div
          className="map_bg"
          key={index}
          style={{ backgroundColor: item.bgColor }}
        >
          <div
            className="month_title"
            style={{ borderLeft: `3px solid ${item.color}` }}
          >
            {item.title}
          </div>
          <p>{item.des}</p>
        </div>
      ))}
    </div>
  );

  return (
    <div className="road_map_box">
      <div className="bg_left_fish">
        <img src={leftFish} alt="" className="left_fish"></img>
        <div className="video-overlay overlay"></div>
        <div className="video-overlay_top overlay"></div>
        <div className="video-overlay_right overlay"></div>
      </div>
      <div className="bg_right">
        <img src={Vectorzise} alt=""></img>
      </div>
      <div className="road_map_content">
        <div className="road_map_title">Roadmap</div>
        <div className="map_box">
          {renderMapGroup(firstGroup, true)}
          {renderMapGroup(secondGroup)}
        </div>
      </div>
    </div>
  );
};
export default RoadMap;
