import React from "react";
import { Carousel } from "antd";
import mark from "assets/img/slider/mark.svg";
import Rectangle1 from "assets/img/slider/Rectangle 16.png";
import Rectangle2 from "assets/img/slider/Rectangle 17.png";
import Rectangle3 from "assets/img/slider/Rectangle 18.png";
import Rectangle4 from "assets/img/slider/Rectangle 19.png";
import Rectangle5 from "assets/img/slider/Rectangle 20.png";
import "./index.scss";

const CarouselSec = () => (
  <div className="carouselSec_box">
    <div className="title">The Features Of Decentralization</div>
    <Carousel autoplay>
      <div className="slider">
        <div>
          <img src={mark} alt=""></img>
        </div>
        <div>
          <p>
            No need for email or phone number registration
            <span> ensuring absolute anonymity</span>.
          </p>
        </div>
        <div>
          <img src={Rectangle1} alt=""></img>
        </div>
      </div>
      <div className="slider">
        <div>
          <img src={mark} alt=""></img>
        </div>
        <div>
          <p>
            Anonymity allows KOLs to set aside the burden of their fan base,
            open up, and <span>speak freely</span>.
          </p>
        </div>
        <div>
          <img src={Rectangle2} alt=""></img>
        </div>
      </div>
      <div className="slider">
        <div>
          <img src={mark} alt=""></img>
        </div>
        <div>
          <p>
            The dissemination of Fugu Code on acquaintances social networks,
            <span> reconstructing social relationships </span>
            while maintaining mutual anonymity.
          </p>
        </div>
        <div>
          <img src={Rectangle3} alt=""></img>
        </div>
      </div>
      <div className="slider">
        <div>
          <img src={mark} alt=""></img>
        </div>
        <div>
          <p>
            The innovative mechanism of burning tokens allows the official Fugu
            account to become <span>the first decentralized</span> X account.
          </p>
        </div>
        <div>
          <img src={Rectangle4} alt=""></img>
        </div>
      </div>
      <div className="slider">
        <div>
          <img src={mark} alt=""></img>
        </div>
        <div>
          <p>
            The text generation short video Sora version is about{" "}
            <span>to be launched</span>.
          </p>
        </div>
        <div>
          <img src={Rectangle5} alt=""></img>
        </div>
      </div>
    </Carousel>
  </div>
);
export default CarouselSec;
