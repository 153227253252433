import { useState, Fragment } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import rightFish from "assets/img/bg/rightFish.gif";
import "./index.scss";

const FAQ = () => {
  const [open, setOpen] = useState(null);

  const handleClick = (index) => {
    setOpen(open === index ? null : index);
  };

  const data = [
    {
      title:
        "If only one friend uses my Fugu code, will he guess my anonymous identity on Fugu through the sorting of Fugu content?",
      details:
        "When a new user posts content, the platform will randomly follow dozens of similar-language posts to confuse those who are trying to guess clues.",
    },
    {
      title: "Will the Friend Tech model be used? Why?",
      details:
        "Yes，We will design the purchase of a key to enter a Fugu cave, but this key will be a utility key rather than solely for price speculation. we name ft‘s key roughly is a security key. \n The Friend Tech (FT) model is employed to enhance user experience and engagement by facilitating connections and interactions between users based on their preferences, interests, and social networks. It helps in suggesting relevant content, recommending friends or connections, and optimizing the overall user experience within the platform. Additionally, the FT model assists in maintaining user privacy and security by ensuring that interactions and connections are made within the bounds of users' preferences and consent.",
    },
    {
      title: "What are the usage scenarios of the token?",
      details: "whitepaper will be disclosed soon.",
    },
    {
      title: "What is the decentralized Fugu official X account?",
      details:
        "Decentralized Fugu official X account refers to an official account within the Fugu platform that operates on a decentralized basis. Unlike traditional centralized social media platforms where accounts are managed by a central authority, the decentralized Fugu official X account operates using decentralized technology. \n Allowing users to burn tokens to post content from Fugu to Twitter under the official Fugu Twitter account's name, amplifying and spreading content from Fugu Toxic to the Twitter ecosystem. Therefore, the Fugu Twitter official account will be controlled by users through token usage, making it a collective media platform.",
    },
  ];

  return (
    <div className="faq_box">
      <div className="bg_right_fish">
        <img src={rightFish} alt="" className="right_fish"></img>
        <div className="video-overlay overlay"></div>
        <div className="video-overlay_top overlay"></div>
        <div className="video-overlay_left overlay"></div>
        <div className="video-overlay_right overlay"></div>
      </div>
      <div className="faq_container">
        <div className="faq-title">Learn more about token extensions</div>
        <div className="faq">
          <List className="stage-list">
            {data.map((item, index) => (
              <Fragment key={index}>
                <ListItem
                  className={`list-item ${open === index ? "active" : ""}`}
                  onClick={() => handleClick(index)}
                >
                  <ListItemText primary={item.title} className="title" />
                  <IconButton>
                    {open === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </ListItem>
                <Collapse in={open === index} timeout="auto" unmountOnExit>
                  <div className="details">
                    {item.details.split("\n").map((line, index, array) => (
                      <Fragment key={index}>
                        {line}
                        {index < array.length - 1 ? (
                          <>
                            <br />
                            <br />
                          </>
                        ) : null}
                      </Fragment>
                    ))}
                  </div>
                </Collapse>
              </Fragment>
            ))}
          </List>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
