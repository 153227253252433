import HomeContent from "section/HomeContent";
import CarouselSec from "section/CarouselSec";
import HowEarn from "section/HowEarn";
import RoadMap from "section/RoadMap";
import FAQ from "section/FAQ";
import Footer from "components/Footer";

import "./index.scss";

function HomePage() {
  return (
    <div className="page_box">
      <HomeContent />
      <CarouselSec />
      <HowEarn />
      <RoadMap />
      <FAQ />
      <Footer />
    </div>
  );
}

export default HomePage;
