import { useState, useEffect } from "react";
import Header from "components/Header";
import PhoneHeader from "components/PhoneHeader";
import banner from "assets/img/banner/banner.mp4";
import phoneBanner from "assets/img/banner/banner.gif";
import Vector from "assets/img/bg/Vector.png";
import Vector1 from "assets/img/banner/Vector.png";
import "./index.scss";

const HomeContent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const CurrentHeader = isMobile ? PhoneHeader : Header;

  const handleOnClick = () => {
    window.open("https://app.fugu.club", "_blank");
  };
  return (
    <>
      <div className="video_bg">
        {isMobile ? (
          <img src={phoneBanner} alt="" className="background-video"></img>
        ) : (
          <video autoPlay muted loop className="background-video">
            <source src={banner} type="video/mp4" />
          </video>
        )}
        <div className="video-overlay"></div>
      </div>
      <div className="bg_left">
        <img src={Vector} alt=""></img>
      </div>
      <div className="content">
        <CurrentHeader />
        <div className="home_box">
          <div className="home_title">
            <span>Freedom of speech</span>
            <span> is the cornerstone of fairness and equality</span>
          </div>
          <div className="home_des">
            In the deep sea, Fugu Toxic is a decentralized anonymous social
            network rebuilt on the basis of familiar social networks
          </div>
          <div className="home_btn" onClick={handleOnClick}>
            <div>
              <img src={Vector1} alt=""></img>
            </div>
            <div>
              <span>FUGUTOXIC </span> <span> LAUNCH</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeContent;
