import logo from "assets/img/header/logo.svg";
import Twitter from "assets/img/footer/Link - Twitter.svg";
// import Discord from "assets/img/footer/Link - Discord.svg";
// import GitHub from "assets/img/footer/Link - GitHub.svg";
import Telegram from "assets/img/footer/Link - Telegram.svg";
import "./index.scss";

const Footer = () => {
  const handleX = () => {
    window.open("https://twitter.com/fugutoxic01", "_blank");
  };
  const handletel = () => {
    window.open("https://t.me/+kYIzVm65ZbE5NWY9", "_blank");
  };
  return (
    <footer className="footer">
      <div className="footer_content">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="footer-links">
          <img src={Twitter} alt="Logo" onClick={handleX} />
          {/* <img src={Discord} alt="Logo" /> */}
          {/* <img src={GitHub} alt="Logo" /> */}
          <img src={Telegram} alt="Logo" onClick={handletel} />
        </div>
      </div>
      <div className="footer-bar"></div>
      <div className="footer-bottom">
        © 2024 Solana Foundation. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
